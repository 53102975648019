import type { Result } from "@frontend/ui-kit/src/types/result";
import { useSafe } from '~/composables/shared/useSafe';
import { useApi } from '~/composables/shared/useApi';
import type {AcceptanceStatus, AcceptanceUIStatus} from '~/layers/settlement/types/realty';
import { AcceptanceStatuses, AcceptanceUIStatuses } from '~/layers/settlement/constants/statuses';
import type { IAcceptanceDialog, IAcceptanceDialogInfo, IAcceptanceDialogSend, IAcceptanceManager, IAcceptanceManagers } from "~/layers/settlement/types/acceptance";
import type {IAcceptanceDatesManager, IAcceptanceTime} from "~/layers/settlement/types/acceptanceDates";
import { RealtyTypes } from "~/constants/realty";
import type { IStringKeyValueData } from "@frontend/ui-kit/src/types/keyValue";
import userHelper from "@frontend/ui-kit/src/utils/userHelper";
import moment from "moment/moment";

export const useAcceptanceDialog = () => {
    const { t } = useI18n();

    const { get, post, put } = useApi();
    const { tryAction } = useSafe();

    const config = useRuntimeConfig();

    /**
    * Получает идентификатор приемки на основе идентификатора объекта
    * @param realtyId Идентификатор объекта
    */
    const getAcceptanceId = async (realtyId: number) : Promise<Result<number>> =>  {
        return await tryAction(async () => {
            const url = `${config.public.settlementApiBaseUrl}/acceptances/appointment/create`;
            return await post<number>(url, { realtyId: realtyId });
        });
    };


    /**
    * Получает все данные для модального окна по выбранной приемке
    * @param acceptanceId Идентификатор заявки
    */
    const getFullAcceptanceInfo = async (acceptanceId: number) : Promise<Result<IAcceptanceDialog>> =>  {
        return await tryAction(async () => {
            const url = `${config.public.settlementApiBaseUrl}/acceptances/${acceptanceId}/detail`;
            return await get<IAcceptanceDialog>(url);
        });
    };

    /**
    * Отправляет обновленные данные по выбранной приемке
    * @param acceptanceId Идентификатор заявки
    * @param data Объект с редактируемыми полями
    */
    const updateAcceptanceInfo = async (acceptanceId: number, data: IAcceptanceDialogSend) : Promise<Result<void>> => {
        return await tryAction(async () => {
            const url = `${config.public.settlementApiBaseUrl}/acceptances/${acceptanceId}`;
            return await put(url, data);
        });
    }

    /** Получает все статусы ОН в виде массива с ключами и значениями */
    const getAcceptanceStatuses = (): IStringKeyValueData[] => {
        const statuses: IStringKeyValueData[] = [
            { type: AcceptanceUIStatuses.Appointmented, name: t('settlement.acceptance_status_manager.appointmented') },
            { type: AcceptanceUIStatuses.Prepared, name: t('settlement.acceptance_status_manager.prepared') },
            { type: AcceptanceUIStatuses.SuccessResult, name: t('settlement.acceptance_status_manager.successResult') },
            { type: AcceptanceUIStatuses.ResultWithRemarks, name: t('settlement.acceptance_status_manager.resultWithRemarks') },
            { type: AcceptanceUIStatuses.FailResult, name: t('settlement.acceptance_status_manager.failResult') },
            { type: AcceptanceUIStatuses.Cancelled, name: t('settlement.acceptance_status_manager.cancelled') }
        ];
        return statuses;
    }

    /**
    * Получает формат документа при помощи названия файла
    * @param filename Имя файоа
    */
    const getFileExtension = (filename: string) : string => {
        const parts = filename.split('.');

        if (parts.length < 2 || parts[parts.length - 1] === '') {
            return '—';
        }

        return parts.pop()!.toLowerCase();;
    };

    /**
    * Генерирует поле для общей информации объекта
    * @param data Объект всех данных по объекту
    * @param fullSquare Площадь в текстовом формате
    * @param realtyTypeName Тип объекта в текстовом формате
    */
    const createInfoArray = (data: IAcceptanceDialog, fullSquare: string, realtyTypeName: string) : IAcceptanceDialogInfo[] => {
        const array = [];
        if (!data || !data.realtyType && data.realtyType !== RealtyTypes.Undefined) {
            return [];
        }

        if (data.realtyType === RealtyTypes.Apartment) {
            array.push({
                icon: 'ri-door-closed-line',
                title: t('settlement.admin.acceptances.realty_info.realtyTypeName'),
                value: realtyTypeName,
            })
        }

        array.push(
            {
                icon: 'ri-square-line',
                title: t('settlement.admin.acceptances.realty_info.square'),
                value: fullSquare,
            },
            {
                icon: 'ri-stairs-line',
                title: t('settlement.admin.acceptances.realty_info.floorNumber'),
                value: data.floorNumber,
            },
            {
                icon: 'ri-paint-brush-line',
                title: t('settlement.admin.acceptances.realty_info.finishing'),
                value: data.isTypeFinish ? t('forms.shared.yesNo.yes') : t('forms.shared.yesNo.no'),
            },
            // временно убрано
            // {
            //     icon: 'ri-building-4-line',
            //     title: t('settlement.admin.acceptances.realty_info.projectName'),
            //     value: data.projectName,
            // },
            {
                icon: 'ri-hotel-line',
                title: t('settlement.admin.acceptances.realty_info.sectionNumber'),
                value: data.sectionNumber,
            },
        );

        return array;
    }

    /**
    * Создает массив доступных менеджеров для селекта в модальном окне
    */
    const createManagerArray = (managers: IAcceptanceManager[]) : IStringKeyValueData[]=> {
        const array = [{
            type: '',
            name: t('forms.shared.dropdown.not_selected'),
        }];

        if (!managers) {
            return array;
        }

        managers.forEach((manager: IAcceptanceManager) => {
            array.push({
                type: manager.id,
                name: userHelper.getFullNameUser(manager.lastName, manager.firstName, manager.patronymic, undefined),
            });
        });

        return array;
    };

    /**  объект для модального окна созданной приемки по умолчанию */
    const defaultAcceptanceData:IAcceptanceDialog = {
        realtyType: 0,
        address: '',
        shortAddress: '',
        number: '',
        acceptanceDate: '',
        schemeUrl: '',
        square: 0,
        acceptanceStatus: AcceptanceStatuses.Undefined,
        acceptanceId: 0,
        acceptanceStatusDescription: "",
        floorNumber: 0,
        isTypeFinish: false,
        sectionNumber: 0,
        projectName: '', // временно убрано
        managerId: "",
        fullNameManager: "",
        lastManagerId: "",
        fullNameLastManager: "",
        accepter: "",
        technicalComment: "",
        remarks: "",
        owners: [],
        rowVersion: null
    };

    /**
    * Возвращает ссылку на содержимое файла
    * @param fileGuid Guid файла
    */
    const getFileContentUrl = (fileGuid: string) : string => {
        const config = useRuntimeConfig();
        return `${config.public.fileApiBaseUrl}/files/settlement/${fileGuid}/content`;
    }

    /**
    * Возвращает полный адрес
    * @param address Адрес дома
    * @param realtyTypeName Тип объекта
    * @param floorNumber Этаж
    * @param number Номер объекта
    */
    const getFullAddress = (
        address: string,
        realtyTypeName: string,
        floorNumber: number,
        number: string
    ) : string => {
        return `${address}, ${t('settlement.common.floor')} ${floorNumber}, ${realtyTypeName} ${number}`;
    }

    /**
    * Возвращает статус приемки на основе типа
    * @param apiStatus статус приемки в виде числа
    */
    const mapFromApiStatus = (apiStatus: AcceptanceStatus | undefined) : AcceptanceUIStatus => {
        // статусы New и Invited в селект не добавляются вообще
        // поэтому статус устанавливается в ближайший по очередности - Appointmented
        if(apiStatus === AcceptanceStatuses.Draft
            || apiStatus === AcceptanceStatuses.Appointmented
            || apiStatus === AcceptanceStatuses.Participant
            || apiStatus === AcceptanceStatuses.CheckPersonalData
            || apiStatus === AcceptanceStatuses.ChooseDate
            || apiStatus === AcceptanceStatuses.NeedPrepareDocuments ) {
            return AcceptanceUIStatuses.Appointmented;
        }
        if(apiStatus === AcceptanceStatuses.Meeting) {
            return AcceptanceUIStatuses.Prepared;
        }
        if(apiStatus === AcceptanceStatuses.SuccessResult) {
            return AcceptanceUIStatuses.SuccessResult;
        }
        if(apiStatus === AcceptanceStatuses.ResultWithRemarks) {
            return AcceptanceUIStatuses.ResultWithRemarks;
        }
        if(apiStatus === AcceptanceStatuses.FailResult) {
            return AcceptanceUIStatuses.FailResult;
        }
        if(apiStatus === AcceptanceStatuses.Cancelled) {
            return AcceptanceUIStatuses.Cancelled;
        }
        return AcceptanceUIStatuses.Undefined;
    }


    /**
    * Возвращает объект с возможными датами и выбранным временем по умолчанию
    * @param accepatanceId идентификатор приемки
    */
    const getAcceptanceDates = async (accepatanceId: number) : Promise<Result<IAcceptanceDatesManager>> => {
        return await tryAction(async () => {
            const url = `${config.public.settlementApiBaseUrl}/acceptances/${accepatanceId}/manager/date`;
            const data = await get<IAcceptanceDatesManager>(url);
            return data;
        });
    };

    /**
    * Возвращает массив дат на текущий день
    * @param date выбранный день
    */
    const getAcceptanceTimes = async (accepatanceId: number, date: string | null) : Promise<Result<string[]>> => {
        return await tryAction(async () => {
            const url = `${config.public.settlementApiBaseUrl}/acceptances/${accepatanceId}/manager/date/${date}/times`;
            const times = await get<string[]>(url);
            return times;
        });
    };

    /**
    * Возвращает массив менеджеров приемки
    * @param take Количество записей, которое необъодимо вернуть.
    * @param skip Количество записей, которое необходимо пропустить.
    */
    const getAcceptanceManagers = async (take? : number, skip?: number) : Promise<Result<IAcceptanceManagers>> => {
        const params = {
            Take: (take ?? 20).toString(),
            Skip: (skip ?? 0).toString(),
        };
        const queryParams = new URLSearchParams(params).toString();

        return await tryAction(async () => {
            const url = `${config.public.securityApiBaseUrl}/user/managers/list?${queryParams}`;
            return await get<IAcceptanceManagers>(url);
        });
    };


    /**
    * Определяет, какая валидация будет производиться в зависимости от статуса
    * @param dataStatus Статус приемки, пришедший с бэка
    * @param status Текущий (выбранный) татус приемки
    * @param selectedDate Выбранная дата
    * @param selectedTime Выбраное время
    * @returns Объект: 1. Успешность валидации, 2. Сообщение об ошибке 3. Статус для отправки
    */
    const setValidate = (
        dataStatus: string | undefined,
        status: string | null,
        selectedDate: Date | null,
        selectedTime: string | null
    ) => {
        let isFormCorrect = false;
        let message = '';

        if (!status) {
            message = t('settlement.admin.acceptances.errors.status');
            return { isFormCorrect, message };
        }

        if (!selectedDate || !selectedTime) {
            message = t('settlement.admin.acceptances.errors.time');
        } else {
            isFormCorrect = true
        }

        return { isFormCorrect, message }
    };

    /**
     * Проверка на то, что выбранное время содержится в списке
     * @param acceptanceDate дата приёмки
     * @param currentDate текущая дата
     * @param times доступное время
     */
    const isCurrentDateInListTimes = (acceptanceDate: Date, currentDate: Date, times: IAcceptanceTime[]) => {
        const acceptanceDateLocal = moment(acceptanceDate)
        const currentDateLocal = moment(currentDate);
        if (acceptanceDateLocal.isSame(currentDateLocal, 'day')) {
            return times.findIndex(item => moment(item.type).isSame(acceptanceDateLocal)) === -1
        }

        return false
    }

    return {
        getAcceptanceId,
        getAcceptanceStatuses,
        getFullAcceptanceInfo,
        updateAcceptanceInfo,
        getFileExtension,
        createInfoArray,
        createManagerArray,
        defaultAcceptanceData,
        getFileContentUrl,
        getFullAddress,
        mapFromApiStatus,
        getAcceptanceDates,
        getAcceptanceTimes,
        setValidate,
        isCurrentDateInListTimes,
        getAcceptanceManagers,
    };
};
